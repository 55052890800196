















import { Component, Ref } from 'vue-property-decorator'
import { MetaInfo } from 'vue-meta'
import { Route } from 'vue-router/types/router'
import { isEqual } from 'lodash'

// components
import Confirmation from '@/components/modals/Confirmation.vue'
import TrainingCommonForm from '@/components/forms/training/TrainingCommonForm.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import ManagerTrainingModule from '@/store/modules/manager/training'
import { QuestionStoreRequest } from '@/store/types/schema.training'


@Component({
  components: {
    Confirmation,
    TrainingCommonForm,
  },
})
export default class New extends NotifyMixin {
  @Ref() confirm!: Confirmation
  @Ref() commonForm!: any

  private isSave = false
  private beforeRouteLeave(to: Route, from: Route, next: any) {
    if (!this.isSave && !isEqual(this.commonForm.form, this.commonForm.localForm)) {
      this.confirm.open(
        'Уйти со страницы?',
        'У вас есть несохранённые изменения, вы уверены, что хотите покинуть страницу?',
        {
          buttonCancelText: 'Вернуться',
          buttonConfirmText: 'Уйти',
          buttonReverse: true,
        },
      )
        .then(() => next())
        .catch(() => next(false))
    } else {
      next()
    }
  }

  private saveQuestion(form: QuestionStoreRequest) {
    ManagerTrainingModule.saveQuestion(form)
      .then(() => {
        this.notifySuccess('Вопрос успешно сохранен')
        this.isSave = true
        this.$router.push({ name: 'manager.training' })
      })
      .catch(this.notifyError)
  }

  private metaInfo (): MetaInfo {
    return {
      title: 'Создание вопроса',
    }
  }
}
