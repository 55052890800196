
































































































































































































































import { Component, Prop, Watch } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import { omit } from 'lodash'
import { v4 as uuid } from 'uuid'

// components
import AttachmentsWrapper from '@/components/_uikit/AttachmentsWrapper.vue'
import Autocomplete from '@/components/_uikit/controls/Autocomplete.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import Parameters from '@/components/_uikit/Parameters.vue'
import TiptapEditor from '@/components/_uikit/editor/TiptapEditor.vue'
import TrainingQuestionRatioForm from '@/components/forms/training/TrainingQuestionRatioForm.vue'
import TrainingQuestionSequenceForm from '@/components/forms/training/TrainingQuestionSequenceForm.vue'
import TrainingQuestionSingleForm from '@/components/forms/training/TrainingQuestionSingleForm.vue'
import TrainingQuestionMultipleForm from '@/components/forms/training/TrainingQuestionMultipleForm.vue'
import TrainingQuestionTextForm from '@/components/forms/training/TrainingQuestionTextForm.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import ManagerTrainingModule from '@/store/modules/manager/training'
import {
  MediaResource,
  NameValueResource,
  QuestionType,
  SectionResource,
  SourceResource,
  ThemeResource,
} from '@/store/types/schema.training'
import { QuestionRequest } from '@/store/types/forms'
import { GET_DEFAULT_TRAINING_ANSWER } from '@/components/forms/training/constants'

enum MenuActions {
  ADD_FILE = 'addFile',
  ADD_HINT_ANSWER = 'addHintAnswer',
}

enum MenuTrainingQuestionActions {
  ADD = 'add',
  COPY = 'copy',
  DELETE = 'delete',
  DOWN = 'down',
  UP = 'up',
}

@Component({
  components: {
    TrainingQuestionMultipleForm,
    TrainingQuestionSingleForm,
    AttachmentsWrapper,
    Autocomplete,
    Parameters,
    Select,
    TiptapEditor,
    TrainingQuestionRatioForm,
    TrainingQuestionSequenceForm,
    TrainingQuestionTextForm,
    ValidationProvider,
  },
})
export default class TrainingQuestionForm extends NotifyMixin {
  @Prop({ required: true })
  private form!: QuestionRequest

  @Prop({ default: -1 })
  private index!: number

  @Prop({ default: false })
  private isChild!: boolean

  @Prop({ default: false })
  private isEdit!: boolean

  @Prop({ default: false })
  private isLast!: boolean

  @Prop({ default: () => ([]) })
  private kimLines!: number[]

  @Prop({ default: () => ([]) })
  private questionTypes!: NameValueResource[]

  @Prop({ default: () => ([]) })
  private sections!: SectionResource[]

  @Prop({ default: () => ([]) })
  private sources!: SourceResource[]

  @Prop({ required: true })
  private subject!: NameValueResource

  private get actions() {
    const actions: any[] = [
      { icon: '$trash', iconColor: 'teal', name: 'Удалить вопрос', value: MenuTrainingQuestionActions.DELETE },
    ]
    if (this.isChild) {
      actions.push(...[
        { icon: '$plus', iconColor: 'teal', name: 'Добавить новый', value: MenuTrainingQuestionActions.ADD },
        { disabled: this.index === 0, icon: '$moveUp', iconColor: 'teal', name: 'Переместить вверх', value: MenuTrainingQuestionActions.UP },
        { disabled: this.isLast, icon: '$moveDown', iconColor: 'teal', name: 'Переместить вниз', value: MenuTrainingQuestionActions.DOWN },
        { icon: '$duplicate', iconColor: 'teal', name: 'Копировать', value: MenuTrainingQuestionActions.COPY },
      ])
    }
    return actions
  }

  private get isQuestionMultiple() {
    return this.form.type === QuestionType.MULTIPLE
  }

  private get isQuestionRatio() {
    return this.form.type === QuestionType.RATIO
  }

  private get isQuestionSequence() {
    return this.form.type === QuestionType.SEQUENCE
  }

  private get isQuestionSingle() {
    return this.form.type === QuestionType.SINGLE
  }

  private get isQuestionText() {
    return this.form.type === QuestionType.TEXT
  }

  private get menu () {
    const list = []
    if (!this.showAdditionalFile) {
      list.push({ icon: '$file', iconColor: 'blue', name: 'Добавить файл', value: MenuActions.ADD_FILE })
    }
    if (!this.showAdditionalHintAnswer) {
      list.push({ icon: '$smile', iconColor: 'blue', name: 'Текст решения', value: MenuActions.ADD_HINT_ANSWER })
    }
    return list
  }

  private get uploadParams () {
    return [{
      name: 'folder',
      value: `${this.subject.name}/Тренажер/Материалы`,
    }]
  }

  private buttonList = ['bold', 'italic', 'underline', 'strike', 'subscript', 'superscript', 'color', 'marker', 'image']

  private showAdditionalFile = false
  private showAdditionalHintAnswer = false

  private themes: ThemeResource[] = []

  private mounted() {
    if (this.form.sectionId && this.form.sectionId > 0) {
      this.fetchThemes(this.form.sectionId)
    }

    if (this.form.mediaIds.length)
      this.showAdditionalFile = true
    if (this.form.rightAnswer)
      this.showAdditionalHintAnswer = true
  }

  private fetchThemes(sectionId: number) {
    ManagerTrainingModule.fetchThemes({ sectionIds: [sectionId] })
      .then(response => {
        this.themes = response
      })
      .catch(this.notifyError)
  }

  private handleAdditionalFile(file: MediaResource) {
    this.form.media.push(file)
  }

  private handleAdditionalAnswerFile(file: MediaResource) {
    this.form.answerMedia.push(file)
  }

  private handleChangeQuestionType() {
    this.form.answers = [GET_DEFAULT_TRAINING_ANSWER()]
    this.form.sequences = []
  }

  private handleCopyQuestion() {
    this.$emit('copy-question', this.index + 1, {
      ...omit(this.form, 'id'),
      answers: this.form.answers.map(answer => omit(answer, 'id')),
      sequences: this.form.sequences.map(sequence => omit(sequence, 'id')),
      uuid: uuid(),
    })
  }

  private handleDeleteAdditionalFile() {
    this.showAdditionalFile = false
    this.form.mediaIds = []
    this.form.media = []
  }

  private handleDeleteHintAnswer() {
    this.showAdditionalHintAnswer = false
    this.form.rightAnswer = null
    this.form.answerMediaIds = []
    this.form.answerMedia = []
  }

  private handleDeleteFile(id: number) {
    this.form.media = this.form.media.filter(media => media.id !== id)
  }

  private handleDeleteAnswerFile(id: number) {
    this.form.answerMedia = this.form.answerMedia.filter(media => media.id !== id)
  }

  private handleMenuItemActionClick(action: MenuTrainingQuestionActions) {
    switch(action) {
    case MenuTrainingQuestionActions.ADD:
      this.$emit('add-question', this.index + 1)
      break
    case MenuTrainingQuestionActions.COPY:
      this.handleCopyQuestion()
      break
    case MenuTrainingQuestionActions.DELETE:
      this.$emit('delete-question', this.index)
      break
    case MenuTrainingQuestionActions.DOWN:
      this.$emit('moveDown', this.index)
      break
    case MenuTrainingQuestionActions.UP:
      this.$emit('moveUp', this.index)
      break
    }
  }

  private handleMenuItemClick (action: MenuActions) {
    switch(action) {
    case MenuActions.ADD_FILE:
      this.showAdditionalFile = true
      break
    case MenuActions.ADD_HINT_ANSWER:
      this.showAdditionalHintAnswer = true
      break
    }
  }

  @Watch('form.sectionId')
  private watchSectionID() {
    if (this.form.sectionId && this.form.sectionId > 0) {
      this.fetchThemes(this.form.sectionId)
    } else {
      this.form.themeId = null as unknown as number
      this.themes = []
    }
  }
}
