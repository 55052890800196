
















































































import { Component, Prop, Ref } from 'vue-property-decorator'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { omit } from 'lodash'

// components
import Confirmation from '@/components/modals/Confirmation.vue'
import Select from '@/components/_uikit/controls/Select.vue'
import TrainingQuestionForm from '@/components/forms/training/TrainingQuestionForm.vue'
// mixins
import NotifyMixin from '@/mixins/NotifyMixin'
// store
import ManagerTrainingModule from '@/store/modules/manager/training'
import {
  NameValueResource, QuestionStatus,
  QuestionStoreRequest,
  QuestionType,
  SectionResource, SingleQuestionResource,
  SourceResource,
  Subjects,
} from '@/store/types/schema.training'
import { QuestionRequest } from '@/store/types/forms'
import { GET_DEFAULT_TRAINING_QUESTION_FORM } from '@/components/forms/training/constants'

@Component({
  components: {
    Confirmation,
    TrainingQuestionForm,
    Select,
    ValidationObserver,
    ValidationProvider,
  },
})
export default class TrainingCommonForm extends NotifyMixin {
  @Ref() confirm!: Confirmation

  @Prop({ default: false })
  private isEdit!: boolean

  private get filter() {
    return ManagerTrainingModule.filter
  }

  private get questionID() {
    return this.$route.params.questionID
  }

  private get subject(): NameValueResource {
    const subject = this.subjects.find(item => item.id === this.form.subjectId)
    if (subject)
      return {
        name: subject.title,
        value: subject.id,
      }
    return {
      name: 'История',
      value: 1,
    }
  }
  private get subjects() {
    return ManagerTrainingModule.subjects
  }

  private get subjectAlias(): Subjects {
    const subject = this.subjects.find(sub => sub.id === this.filter.subjectId)
    return subject ? subject.alias : Subjects.HISTORY
  }

  private get trainingID() {
    return this.form.id ?? null
  }

  private isLoading = false
  private isPublished = false

  private form: QuestionRequest = {
    ...GET_DEFAULT_TRAINING_QUESTION_FORM(),
    subject: this.subjectAlias,
    subjectId: this.filter.subjectId,
  }

  private localForm: QuestionRequest = {
    ...GET_DEFAULT_TRAINING_QUESTION_FORM(),
    subject: this.subjectAlias,
    subjectId: this.filter.subjectId,
    uuid: this.form.uuid,
  }

  private kimLines: number[] = []
  private questionTypes: NameValueResource[] = []
  private sections: SectionResource[] = []
  private sources: SourceResource[] = []

  private mounted() {
    this.fetchQuestionTypes()
    // Запрашиваем справочник предметов для фильтра
    if (!this.subjects.length) {
      ManagerTrainingModule.fetchSubjects()
        .catch(this.notifyError)
    }
    this.fetchSections(this.filter.subjectId)
    this.fetchKIM(this.filter.subjectId)
    this.fetchSources(this.filter.subjectId)
    if (this.isEdit) {
      this.fetchQuestion(+this.questionID)
    }
  }

  private fetchKIM(subjectId: number) {
    ManagerTrainingModule.fetchKIM(subjectId)
      .then(response => {
        this.kimLines = response
      })
      .catch(this.notifyError)
  }

  private fetchQuestion(questionID: number) {
    this.isLoading = true
    ManagerTrainingModule.fetchQuestion(questionID)
      .then(response => {
        this.isPublished = response.isPublished.value === QuestionStatus.PUBLISHED
        this.form = {
          ...this.setQuestionForm(response),
          related: response.related.map(relate => this.setQuestionForm(relate)),
        }
        this.localForm = {
          ...this.setQuestionForm(response),
          related: response.related.map(relate => this.setQuestionForm(relate)),
        }
        this.isLoading = false
      })
      .catch(err => {
        this.notifyError(err)
        this.$router.replace({ name: 'manager.training' })
      })
  }

  private fetchQuestionTypes() {
    ManagerTrainingModule.fetchQuestionTypes()
      .then(response => {
        this.questionTypes = response
      })
      .catch(this.notifyError)
  }

  private fetchSections(subjectId: number) {
    ManagerTrainingModule.fetchSections({ subjectId })
      .then(response => {
        this.sections = response
      })
      .catch(this.notifyError)
  }

  private fetchSources(subjectId: number) {
    ManagerTrainingModule.fetchSources({ subjectId })
      .then( response => {
        this.sources = response
      })
      .catch(this.notifyError)
  }

  private handleAddRelatedQuestion(index?: number) {
    if (index === undefined)
      this.form.related.push({
        ...GET_DEFAULT_TRAINING_QUESTION_FORM(),
        relatedUuid: this.form.uuid,
        subject: this.subjectAlias,
        subjectId: this.form.subjectId,
      })
    else
      this.form.related.splice(index, 0, {
        ...GET_DEFAULT_TRAINING_QUESTION_FORM(),
        relatedUuid: this.form.uuid,
        subject: this.subjectAlias,
        subjectId: this.form.subjectId,
      })
  }

  private handleChangeSubject(value: number) {
    this.form = {
      ...this.form,
      kimLine: null as unknown as number,
      related: this.form.related.map(relate => ({
        ...relate,
        kimLine: null as unknown as number,
        sectionId: null as unknown as number,
        sourceId: null as unknown as number,
        subjectId: value,
        themeId: null as unknown as number,
      })),
      sectionId: null as unknown as number,
      sourceId: null as unknown as number,
      themeId: null as unknown as number,
    }
    this.fetchSections(value)
    this.fetchKIM(value)
    this.fetchSources(value)
  }

  private handleCopyRelatedQuestion(index: number, question: QuestionStoreRequest) {
    this.form.related.splice(index, 0, question)
  }

  // Удалить вопрос полностью
  private handleDeleteQuestion() {
    this.confirm.open(
      'Удаление вопроса',
      'Вы действительно хотите удалить вопрос?',
      {
        buttonConfirmText: 'Удалить',
      },
    )
      .then(() => {
        if (this.trainingID)
          ManagerTrainingModule.deleteQuestion({ questionID: this.trainingID })
            .then(() => {
              this.$emit('afterDeleteQuestion')
            })
            .catch(this.notifyError)
      })
      .catch(() => {return})
  }

  private handleDeleteRelatedQuestion(index: number) {
    if (this.isEdit) {
      const { id } = this.form.related[index]
      if (id)
        ManagerTrainingModule.deleteQuestion({ questionID: id, options: { loading: false } })
    }
    this.form.related.splice(index, 1)
  }

  private handleMoveUpRelatedQuestion(index: number) {
    const question = this.form.related[index]
    this.form.related.splice(index, 1)
    this.form.related.splice(index - 1, 0, question)
  }

  private handleMoveDownRelatedQuestion(index: number) {
    const question = this.form.related[index]
    this.form.related.splice(index, 1)
    this.form.related.splice(index + 1, 0, question)
  }

  private setQuestionForm(form: SingleQuestionResource): QuestionRequest {
    return {
      answers: form.answers.map(answer => ({
        id: answer.id,
        isCorrect: !!answer.isCorrect,
        position: answer.position,
        sequencePosition: answer.sequencePosition || undefined,
        text: answer.text,
      })),
      answerMediaIds: form.answerMedia.map(media => media.id),
      answerMedia: form.answerMedia,
      displayType: form.displayType,
      id: form.id,
      kimLine: form.kimLine,
      mediaIds: form.media.map(media => media.id),
      media: form.media,
      question: form.question,
      related: [],
      rightAnswer: form.rightAnswer,
      sectionId: form.section.value as number,
      sequences: form.sequences,
      sourceId: form.source.value as number,
      subject: this.subjects.find(sub => sub.id === +form.subject.value)?.alias || Subjects.HISTORY,
      subjectId: form.subject.value as number,
      themeId: form.theme.value as number,
      type: form.type,
      uuid: form.uuid,
    }
  }

  private submit() {
    const form: any = this.$refs.form

    form.validate()
      .then(async (result: boolean) => {
        if (result) {
          let data: QuestionStoreRequest = Object.assign({}, this.form)
          data = omit(data, ['answerMedia', 'media'])
          data.subject = this.subjects.find(sub => sub.id === data.subjectId)?.alias || Subjects.HISTORY
          data.answers = data.answers.map((answer, idx) => ({ ...answer, position: idx + 1 }))
          data.sequences = data.sequences.map((sequence, idx) => ({ ...sequence, position: idx + 1 }))
          if (data.type === QuestionType.TEXT)
            data.answers.splice(data.answers.length - 1, 1)
          if (data.type === QuestionType.EMPTY || data.type === QuestionType.NO_ANSWER) {
            data.answers = []
            data.sequences = []
          }
          data.related = data.related.map((question, index) => {
            if (question.type === QuestionType.TEXT)
              question.answers.splice(question.answers.length - 1, 1)
            return {
              ...question,
              answers: question.type === QuestionType.NO_ANSWER || question.type === QuestionType.EMPTY ? [] : question.answers.map((answer, idx) => ({ ...answer, position: idx + 1 })),
              position: index + 1,
              relatedUuid: data.uuid,
              sequences: question.type === QuestionType.NO_ANSWER || question.type === QuestionType.EMPTY ? [] : question.sequences.map((sequence, idx) => ({ ...sequence, position: idx + 1 })),
            }
          })
          ManagerTrainingModule.updateFilter({
            kimLine: undefined,
            page: 1,
            perPage: 10,
            search: undefined,
            sectionId: undefined,
            subjectId: data.subjectId,
            themeId: undefined,
          })
          this.$emit('submit', data)
        } else {
          this.notifyError('Проверьте введенные данные')
        }
      })
  }

  private togglePublishQuestion() {
    this.confirm.open(
      'Публикация/скрытие вопроса',
      'Обратите внимание, что при публикации или скрытии вопроса это действие распространяется на все связанные с ним вопросы.',
      {
        buttonConfirmText: 'Продолжить',
      },
    )
      .then(() => {
        if (this.form.id) {
          ManagerTrainingModule.publishQuestion({
            body: {
              isPublished: !this.isPublished,
            },
            questionID: this.form.id,
          })
            .then(response => {
              this.isPublished = !this.isPublished
              this.notifySuccess(response.isPublished ? 'Вопрос успешно опубликован' : 'Вопрос успешно скрыт')
            })
            .catch(this.notifyError)
        }
      })
      .catch(() => {return})
  }
}
