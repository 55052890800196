





































































































import { Component, Prop } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import Draggable from 'vuedraggable'

// components
import TextAreaInput from '@/components/_uikit/controls/TextAreaInput.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import TrainingQuestionMixin from '@/mixins/manager/TrainingQuestionMixin'
// store
import { NameValueResource, QuestionSequencePart } from '@/store/types/schema.training'
// utils
import { alphabet, alphabetEng } from '@/utils/constants'
import { GET_DEFAULT_TRAINING_ANSWER } from '@/components/forms/training/constants'

@Component({
  components: {
    Draggable,
    TextAreaInput,
    TextInput,
    ValidationProvider,
  },
})
export default class TrainingQuestionRatioForm extends TrainingQuestionMixin {
  @Prop({ required: true })
  private subject!: NameValueResource

  private alphabet = this.subject.value === 6 ? alphabetEng : alphabet
  private state = 'column'

  private mounted() {
    this.state = this.form.displayType || 'column'
  }

  private handleAddSequence() {
    this.form.sequences.push({
      ...GET_DEFAULT_TRAINING_ANSWER(),
      position: this.form.sequences.length + 1,
    })

    setTimeout(() => {
      const { sequences } = this.$refs as any
      sequences[sequences.length - 1].$el.querySelector('textarea').focus()
    }, 100)
  }

  private handleChangeDisplayType(view: string) {
    this.state = view
    this.form.displayType = view
  }

  private handleChangeSubmit(id: number) {
    this.alphabet = id === 6 ? alphabetEng : alphabet
  }

  protected handleSequencesDrag () {
    this.form.sequences.forEach((sequence: QuestionSequencePart, index) => {
      sequence.position = index + 1
    })
  }
}
