


























import { Component } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'

// components
import QuestionIndicator from '@/components/_uikit/QuestionIndicator.vue'
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import TrainingQuestionMixin from '@/mixins/manager/TrainingQuestionMixin'
import { GET_DEFAULT_TRAINING_ANSWER } from '@/components/forms/training/constants'

@Component({
  components: {
    QuestionIndicator,
    TextInput,
    ValidationProvider,
  },
})
export default class TrainingQuestionTextForm extends TrainingQuestionMixin {
  private handleEnter(index: number) {
    if (index === this.form.answers.length - 2) {
      const { answers } = this.$refs as any
      const lastAnswerInput = answers[answers.length - 1].$el.querySelector('input')

      if (lastAnswerInput) {
        lastAnswerInput.focus()
      }
    }
  }

  private handleAnswerInput(value: string, index: number) {
    if (index === this.form.answers.length - 1) {
      this.form.answers.push({
        ...GET_DEFAULT_TRAINING_ANSWER(),
        position: this.form.answers.length + 1,
        text: '',
      })
      setTimeout(() => {
        const { answers } = this.$refs as any
        const lastAnswerInput = answers[answers.length - 2].$el.querySelector('input')

        if (lastAnswerInput) {
          lastAnswerInput.focus()
        }
      })
    }

    if (value === '' && index === this.form.answers.length - 2) {
      this.form.answers.splice(index, 1)
      setTimeout(() => {
        const { answers } = this.$refs as any
        const lastAnswerInput = answers[answers.length - 1].$el.querySelector('input')

        if (lastAnswerInput) {
          lastAnswerInput.focus()
        }
      })
    }
  }
}
