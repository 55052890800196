





























import { Component } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import Draggable from 'vuedraggable'

// components
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import TrainingQuestionMixin from '@/mixins/manager/TrainingQuestionMixin'

@Component({
  components: {
    Draggable,
    TextInput,
    ValidationProvider,
  },
})
export default class TrainingQuestionSequenceForm extends TrainingQuestionMixin {}
