import { Component, Prop, Vue } from 'vue-property-decorator'

import { QuestionAnswerPart, QuestionStoreRequest } from '@/store/types/schema.training'
import { GET_DEFAULT_TRAINING_ANSWER } from '@/components/forms/training/constants'

@Component
export default class TrainingQuestionMixin extends Vue {
  @Prop({ required: true })
  protected form!: QuestionStoreRequest

  protected maxAnswersCount = 8

  protected handleAnswersDrag() {
    this.form.answers.forEach((answer: QuestionAnswerPart, index: number) => {
      answer.position = index + 1
    })
  }

  protected handleAddAnswer(type?: string) {
    this.form.answers.push({
      ...GET_DEFAULT_TRAINING_ANSWER(),
      position: this.form.answers.length + 1,
    })

    setTimeout(() => {
      const { answers } = this.$refs as any
      const lastAnswerInput = answers[answers.length - 1].$el.querySelector(type === 'ratio' ? 'textarea' : 'input')

      if (lastAnswerInput) {
        lastAnswerInput.focus()
      }
    }, 100)
  }
}
