




































import { Component } from 'vue-property-decorator'
import { ValidationProvider } from 'vee-validate'
import Draggable from 'vuedraggable'

// components
import TextInput from '@/components/_uikit/controls/TextInput.vue'
// mixins
import TrainingQuestionMixin from '@/mixins/manager/TrainingQuestionMixin'
import { TestAnswerRequest } from '@/store/types'

@Component({
  components: {
    Draggable,
    TextInput,
    ValidationProvider,
  },
})
export default class TrainingQuestionSingleForm extends TrainingQuestionMixin {
  private rightIndex = 0

  private mounted () {
    const correctIndex = this.form.answers.findIndex((answer: TestAnswerRequest) => answer.isCorrect)
    if (correctIndex) {
      this.rightIndex = correctIndex
    } else {
      this.form.answers[0].isCorrect = true
    }
  }

  private handleChangeRightAnswer (index: number) {
    this.form.answers.forEach((answer: TestAnswerRequest) => { answer.isCorrect = false })
    this.form.answers[index].isCorrect = true
  }
}
