import { v4 as uuid } from 'uuid'

import { QuestionAnswerPart, QuestionType, Subjects } from '@/store/types/schema.training'
import { QuestionRequest } from '@/store/types/forms'

export const GET_DEFAULT_TRAINING_ANSWER = (): QuestionAnswerPart => ({
  isCorrect: false,
  position: 1,
  text: '',
})

export const GET_DEFAULT_TRAINING_QUESTION_FORM = (): QuestionRequest => ({
  answers: [GET_DEFAULT_TRAINING_ANSWER()],
  answerMedia: [],
  answerMediaIds: [],
  displayType: 'column',
  kimLine: null as unknown as number,
  mediaIds: [],
  media: [],
  question: '',
  related: [],
  rightAnswer: null,
  sectionId: null as unknown as number,
  sequences: [],
  sourceId: null as unknown as number,
  subject: Subjects.HISTORY,
  subjectId: 1,
  themeId: null as unknown as number,
  type: QuestionType.TEXT,
  uuid: uuid(),
})
